<template>
  <a-card>
    <template
      #header-title
    >
      <b-icon
        icon="cloud-upload"
      />
      <span>{{ $t('components.files.header') }}</span>
    </template>

    <div
      v-if="files"
      class="list is-hoverable"
    >
      <file
        v-for="file in files"
        :key="file.name"
        :file="file"
        :can-update="canUpdate"
      />
    </div>

    <template
      v-if="canUpdate"
      #form
    >
      <a-upload
        :existing-files="files"
        @completed="refresh"
      />
    </template>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import File from '@/components/File'

export default {
  components: {
    File
  },
  props: {
    filesPath: {
      type: String,
      required: false
    },
    canUpdate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      defaultFilesPath: 'currentDoc/filesPath'
    }),
    computedFilesPath() {
      return this.filesPath || this.defaultFilesPath
    }
  },
  asyncComputed: {
    async files () {
      const storageRef = await firebase.storage().ref()
      const pathRef = await storageRef.child(this.computedFilesPath)
      const files = await pathRef.listAll()
      return files.items
    }
  },
  methods: {
    refresh () {
      console.log('refreshing')
      this.$asyncComputed.files.update()
    }
  }
}
</script>
