<template>
  <div class="level">
    <a
      :href="url"
      class="list-item level-left"
      target="_blank"
    >
      <span
        v-if="custom && custom.type"
        :title="file.name"
      >{{ custom.type }}</span>
      <span v-else>{{ file.name }}</span>

      <span class="is-pulled-right">
        <b-icon icon="download" />
      </span>
    </a>

    <div
      v-if="canUpdate"
      class="level-right"
    >
      <b-icon
        icon="delete"
        type="primary"
        @click.native="removeFile"
      />
    </div>
  </div>
</template>

<script>
import { toPairs } from 'lodash'
import firebase from 'firebase'
export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    canUpdate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      url: ''
    }
  },
  asyncComputed: {
    async metadata () {
      try {
        const fileRef = await firebase.storage().refFromURL(this.file.toString())
        const metadata = await fileRef.getMetadata()
        return metadata
      } catch (e) {
        console.log(e)
      }
    },
    async url () {
      const fileRef = await firebase.storage().refFromURL(this.file.toString())
      const url = await fileRef.getDownloadURL()
      return url
    },
    async fileRef () {
      const fileRef = await firebase.storage().refFromURL(this.file.toString())
      return fileRef
    }

  },
  computed: {
    custom () {
      return this.metadata && this.metadata.customMetadata ? this.metadata.customMetadata : null
    }
  },
  methods: {
    toPairs,
    removeFile() {
      const self = this
      this.$buefy.dialog.confirm({
        message: this.$t('components.file.confirm', { filename: this.file.name }),
        confirmText: this.$t('common.delete'),
        onConfirm: () => {
          self.fileRef.delete()
          self.$el.parentNode.removeChild(this.$el)
        }
      })
    }
  }
}
</script>
