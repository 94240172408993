<template>
  <div>
    <a-card
      v-if="doc"
      :editable="false"
    >
      <template
        #header-title
      >
        <b-icon
          icon="truck-fast-outline"
        />
        <span>&nbsp;{{ $t('failureSupplies.form.header', {
          number: doc.number,
          supplier: doc.supplier.name
        }) }}</span>
      </template>
      <template
        #header-buttons
      >
        <a-select
          v-if="supplier"
          field="contact"
          :label="$t('common.contact')"
          :options="values(supplier.contacts)"
          null-option
        />
        <b-button
          v-if="!creating"
          @click="() => createDeliveryNote()"
        >
          {{ $t('failureSupplies.form.generate_delivery_note') }}
        </b-button>
        <div class="buttons is-pulled-right">
          <b-button
            v-if="user.role === 'admin'"
            tag="a"
            type="is-danger"
            icon-left="delete"
            @click="remove"
          >
            {{ $t('common.delete') }}
          </b-button>
        </div>
      </template>

      <div class="columns">
        <div class="column">
          <a-input
            :label="$t('common.note')"
            field="note"
            type="textarea"
          />
        </div>
        <div class="column">
          <a-input
            :label="$t('common.internal_note')"
            field="internalNote"
            type="textarea"
          />
        </div>
        <div class="column">
          <files
            :key="filesKey"
          />
        </div>
      </div>
    </a-card>

    <a-card
      v-if="doc"
      :editable="false"
    >
      <a-table
        :data="failureParts"
        :columns="user.routes.failureSupplie.columns"
        module-name="failureParts"
        :paginated="false"
      >
        <template
          #actions="slotProps"
        >
          <a
            :title="$t('common.navigation.go_to_failure ')"
          >
            <b-icon
              icon="arrow-up"
              @click.native="goToFailure(slotProps.props.row)"
            />
          </a>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { values, random } from 'lodash'
import Files from '@/components/Files'

export default {
  components: {
    Files
  },
  data() {
    return {
      creating: false,
      filesKey: random(1,99999)
    }
  },
  computed: {
    ...mapGetters({
      failureParts: 'failureParts/currentSupplyItems',
      filesPath: 'currentDoc/filesPath',
      suppliers: 'suppliers/data'
    }),
    supplier() {
      return this.suppliers && this.doc.supplier.id ? this.suppliers[this.doc.supplier.id] : null
    }
  },
  async created () {
    await this.$store.dispatch('currentDoc/loadDoc')
    await this.$store.dispatch('suppliers/fetchById', this.doc.supplier.id)
    await this.$store.dispatch('currentDoc/loadChildren', {
      module: 'failureParts',
      ref: 'failureSupply.id'
    })

    if(!this.doc.contact && values(this.supplier.contacts).length === 1) {
      this.$store.dispatch('currentDoc/set', {
        field: 'contact',
        value: values(this.supplier.contacts)[0]
      })
    }
  },
  methods: {
    values,
    async remove() {
      const self = this
      this.$buefy.dialog.confirm({
        message: this.$t('failureSupplies.form.remove_confirm'),
        type: 'is-warning',
        confirmText: this.$t('common.delete'),
        onConfirm: async () => {
          await self.$store.dispatch('failureSupplies/remove', this.doc.id)
          self.$router.push({ name: 'failureSupplies' })
          self.$buefy.toast.open({
            message: this.$t('failureSupplies.form.remove_success'),
            position: 'is-bottom',
            type: 'is-success'
          })
        }
      })
    },
    goToFailure(failurePart) {
      this.$router.push({ name: 'failure', params: { id: failurePart.failure.id } })
    },
    async createDeliveryNote () {
      this.creating = true
      const response = await fetch('https://us-central1-rams-e6d4f.cloudfunctions.net/requests-createPdf', {
        method: 'POST',
        body: JSON.stringify({
          filename: `${this.filesPath}/deliveryNote.pdf`,
          data: _.pick(this, ['doc', 'failureParts']),
          template: 'DeliveryNote.vue',
          locale: 'en'
        })
      })
      this.creating = false
      this.key = random
      this.filesKey = random(1, 99999)
    }
  }
}
</script>
